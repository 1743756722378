// Queries
import LIQUIDATION from '@/assets/graphql/queries/liquidations/Liquidation.graphql';
import LIQUIDATIONS from '@/assets/graphql/queries/liquidations/Liquidations.graphql';
import PARTNER_REFUNDS from '@/assets/graphql/queries/liquidations/PartnerRefunds.graphql';
import PARTNER_RETENTIONS from '@/assets/graphql/queries/liquidations/PartnerRetentions.graphql';
import PREPAID_VALIDATIONS from '@/assets/graphql/queries/liquidations/PrepaidValidations.graphql';
import PREPAID_VALIDATIONS_TRAVEL from '@/assets/graphql/queries/liquidations/PrepaidValidationsTravel.graphql';
import RESERVATION_VALIDATIONS from '@/assets/graphql/queries/liquidations/ReservationValidations.graphql';
import TRAVEL_RESERVATION_VALIDATIONS from '@/assets/graphql/queries/liquidations/TravelReservationValidations.graphql';
import PARTNERS_LIQUIDATION_PREFERENCES from '@/assets/graphql/queries/liquidations/PartnersLiquidationPreferences.graphql';
//  Mutations
import CREATE_BILL_FILE_MUTATION from '@/assets/graphql/mutations/liquidations/CreateBillFileMutation.graphql';
import CREATE_LIQUIDATION_MUTATION from '@/assets/graphql/mutations/liquidations/CreateLiquidationMutation.graphql';
import DELETE_BILL_FILE_MUTATION from '@/assets/graphql/mutations/liquidations/DeleteBillFileMutation.graphql';
import DELETE_LIQUIDATION from '@/assets/graphql/mutations/liquidations/DeleteLiquidation.graphql';
import PROCESS_PDFDOCUMENT_MUTATION from '@/assets/graphql/mutations/liquidations/ProcessPDFDocumentMutation.graphql';
import PROCESS_XML_MEX_DOCUMENT_MUTATION from '@/assets/graphql/mutations/liquidations/ProcessXMLMexDocumentMutation.graphql';
import XML_AND_PDF_LIQUIDATION_MUTATION from '@/assets/graphql/mutations/liquidations/XMLAndPDFLiquidationMutation.graphql';
import CREATE_LIQUIDATION_EXPRESS_MUTATION from '@/assets/graphql/mutations/liquidations/CreateLiquidationExpressMutation.graphql';
import VERIFY_BILL_NUMBER_MUTATION from '@/assets/graphql/mutations/liquidations/VerifyBillNumberMutation.graphql';

// service
import apolloService from '@/services/apolloService';

import * as Types from './types';

export default class {
  // QUERIES
  static getLiquidation(variables: { id: string }) {
    return apolloService.query<Types.PartnerLiquidationResponse>(
      LIQUIDATION,
      variables,
      {
        fetchPolicy: 'no-cache',
      },
    );
  }

  static getLiquidations(variables: { code: string }) {
    return apolloService.query<Types.PartnerLiquidationsResponse>(
      LIQUIDATIONS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getRefunds() {
    return apolloService.query<Types.PartnerRefundsResponse>(
      PARTNER_REFUNDS,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getRetentions() {
    return apolloService.query<Types.PartnerResetentionsResponse>(
      PARTNER_RETENTIONS,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getPrepaidValidations() {
    return apolloService.query<Types.PrepaidValidationsResponse>(
      PREPAID_VALIDATIONS,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getPrepaidTravelValidations() {
    return apolloService.query<Types.PrepaidTravelValidationsResponse>(
      PREPAID_VALIDATIONS_TRAVEL,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getReservationValidations() {
    return apolloService.query<Types.ReservationValidationsResponse>(
      RESERVATION_VALIDATIONS,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getTravelReservationValidations() {
    return apolloService.query<Types.TravelReservationValidationsResponse>(
      TRAVEL_RESERVATION_VALIDATIONS,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getsLiquidationPreferences() {
    return apolloService.query<Types.PartnerLiquidationPreferencesResponse>(
      PARTNERS_LIQUIDATION_PREFERENCES,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  // MUTATIONS
  static createBillFile(variables: Types.CreateBillFilePayload) {
    return apolloService.mutate<Types.CreateBillFileResposne>(
      CREATE_BILL_FILE_MUTATION,
      variables,
      {},
      'upload',
    );
  }

  static createLiquidation(variables: Types.NewLiquidationPayload) {
    return apolloService.mutate<Types.CreateLiquidationResponse>(
      CREATE_LIQUIDATION_MUTATION,
      variables,
      {},
      'upload',
    );
  }

  static deleteLiquidationBillFile(variables: { document_id: string }) {
    return apolloService.mutate<Types.DeleteBillFileResponse>(
      DELETE_BILL_FILE_MUTATION,
      variables,
    );
  }

  static deleteLiquidation(variables: { liquidation_id: string }) {
    return apolloService.mutate<Types.DeleteLiquidationResponse>(
      DELETE_LIQUIDATION,
      variables,
    );
  }
  static processPDFDocument(variables: { billDocument: File }) {
    return apolloService.mutate<Types.ProcessPDFDocumentResponse>(
      PROCESS_PDFDOCUMENT_MUTATION,
      variables,
      {},
      'upload',
    );
  }
  static processXMLMexDocument(variables: { billDocument: File }) {
    return apolloService.mutate<Types.ProcessXMLMexDocumentResponse>(
      PROCESS_XML_MEX_DOCUMENT_MUTATION,
      variables,
      {},
      'upload',
    );
  }
  static createXMLAndPDFLiquidation(variables: {
    data: Types.CreateXMLAndPDFLiquidationPayload;
  }) {
    return apolloService.mutate<Types.CreateXMLAndPDFLiquidationResponse>(
      XML_AND_PDF_LIQUIDATION_MUTATION,
      variables,
      {},
      'upload',
    );
  }

  static createLiquidationExpress(
    variables: Types.NewExpressLiquidationPayload,
  ) {
    return apolloService.mutate<Types.CreateLiquidationExpressResponse>(
      CREATE_LIQUIDATION_EXPRESS_MUTATION,
      variables,
      {},
      'upload',
    );
  }
  static verifyBillNumber(variables: Types.VerifyBillPayload) {
    return apolloService.mutate<Types.VerifyBillResponse>(
      VERIFY_BILL_NUMBER_MUTATION,
      variables,
    );
  }
}
